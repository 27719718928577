<template>
  <div class="container-fluid">
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h5>
                        الاستبيانات
                    </h5>
                </div>
                <div class="card-footer">
                    <div class="g">
                        <button class="btn btn-success btn-sm" style="border-radius: 0px" @click="page=0; perpage = 1099999; gett(true)">
                            <i class="fa fa-download"></i>
                            تصدير الى Excel
                        </button>
                        &nbsp;
                        <select style="border: 1px solid #999;" v-model="contact" @change="gett()">
                            <option value="all">وسيلة التواصل (الكل)</option>
                            <option value="نعم">من رد بـ"نعم"</option>
                            <option value="v">من رد بوسيلة اخرى</option>
                        </select>
                    </div>
                    <div class="col-12 table-responsive">
                        <table class="table table-sm table-bordered table-hover" id="table">
                            <tr>
                                <th>
                                    الحاج
                                </th>
                                <th>
                                    التاريخ
                                </th>
                                <th>
                                    وسيلة الوصول سهلة
                                </th>
                                <th>
                                    وسيلة التواصل
                                </th>
                                <th>
                                    مدينة السكن
                                </th>
                                <th>
                                    تاكيد الحضور
                                </th>
                            </tr>
                            <tbody>
                                <tr v-for="item in items" :key="item._id">
                                    <td>
                                        {{ item.name }}
                                    </td>
                                    <td>
                                        {{ item.date }}
                                    </td>
                                    <td>
                                        {{ item.easy }}
                                    </td>
                                    <td>
                                        {{ item.contact }}
                                    </td>
                                    <td>
                                        {{ item.address }}
                                    </td>
                                    <td>
                                        {{ item.attend }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12">
                        <button class="btn btn-danger" @click="page--; gett()" v-if=" page > 0 && !loading">الصفحة السابقة <i class="fa fa-arrow-right"></i></button>&nbsp;
                        <button class="btn btn-primary" @click="page++; gett()" v-if="items.length > 99 && !loading">الصفحة التالية <i class="fa fa-arrow-left"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {  VBModal, BModal, BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BDropdown,
    BDropdownItem,
    BModal
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            current: {},
            items: [],
            loading: false,
            user: JSON.parse(localStorage.getItem("user")),
            create: {},
            page: 0,
            perpage: 100,
            contact: "all"
        }
    },
    created(){
        if(!checkPer('ques')){
            this.$router.push('/pers')
            return;
        }
        var g = this;
        g.gett();
    },
    methods: {
        gett(excel=false){
            var g = this;
            g.loading = true;
            $.post(api + '/admin/web/ques', {
                jwt: g.user.jwt,
                page: g.page,
                contact: g.contact,
                perpage: g.perpage
            }).then(function(r){
                g.items = r.response;
                g.loading = false;
                if(excel){
                    g.excel()
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        excel(){
        var g =this;
            g.printing = true
            setTimeout(() => {
                var table_id = "table"
                var separator = ','
                // Select rows from table_id
                var rows = document.querySelectorAll('table#' + table_id + ' tr');
                // Construct csv
                var csv = [];
                for (var i = 0; i < rows.length; i++) {
                    var row = [], cols = rows[i].querySelectorAll('td, th');
                    for (var j = 0; j < cols.length; j++) {
                        // Clean innertext to remove multiple spaces and jumpline (break csv)
                        var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
                        data = data.replace(/"/g, '""');
                        // Push escaped string
                        row.push('"' + data + '"');
                    }
                    csv.push(row.join(separator));
                }
                var csv_string = csv.join('\n');
                // Download it
                var filename = 'bashaer_' + new Date().toLocaleDateString() + "_" + Math.random().toString().split(".")[1].substring(0,3) + '.csv';
                var link = document.createElement('a');
                link.style.display = 'none';
                link.setAttribute('target', '_blank');
                link.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string));
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setTimeout(() => {
                    g.printing = false;
                }, 500);
            }, 100);
        }
    }
}
</script>
